import ProductGroup from '../../constants/productGroup';
import RequestV2 from './request-v2';

export interface ListCubageParams {
    skip?: number;
    take?: number;
    term?: string;
}

export default class CubageHttpService {
    public static uriV2 = 'product/cubage';

    public static create(productGroup: ProductGroup, data: {}) {
        return RequestV2.post(`${this.uriV2}/${productGroup}`, data);
    }

    public static update(productGroup: ProductGroup, id: number, data: {}) {
        return RequestV2.put(`${this.uriV2}/${productGroup}/${id}`, data);
    }

    public static delete(productGroup: ProductGroup, id: number) {
        return RequestV2.del(`${this.uriV2}/${productGroup}/${id}`);
    }

    public static readMany(
        productGroup: ProductGroup,
        params: ListCubageParams,
    ) {
        return RequestV2.get(`${this.uriV2}/${productGroup}`, params);
    }
}
